import BasePlugin from '../BasePlugin'

export default class FillingRequiredDocuments extends BasePlugin {
  async execute () {
    let payload = {
      'recordId': this.context.getModel().id,
      'registryId': 87
    }
    this.send(payload).then((response) => {
      Object.values(this.context.getDashboardComponents()).forEach(function (item) {
        if (item[0].id === 742) {
          item[0].loadData()
        }
      })
    })
  }
}
